<template>
    <user-dashboard-layout>
        <v-card>
            <v-card-title>
                Your Company Progress
            </v-card-title>
            <v-card-text v-if="company">
                <div>
                    <v-progress-linear  height="25" v-model="score" v-if="score>0" :key="`score`+score">
                        <strong>{{ Math.ceil(score) }}%</strong>
                    </v-progress-linear>
                </div>
            </v-card-text>
        </v-card>
    </user-dashboard-layout>
</template>

<script>
    import UserDashboardLayout from "../../layouts/UserDashboardLayout";
    import Assessment from "../../models/Assessment";
    export default {
        name: "dashboard-index",
        components: {UserDashboardLayout},
        data: function () {
            return {
                company: null,
                score: 0
            }
        },
        async mounted() {
            console.log(this.$auth.user());
            this.company = this.$auth.user().company;

            let assessment = await Assessment.custom('assessments/latest').first()
            this.score = assessment ? assessment.score : 0;
            console.log(this.score)
        }
    }
</script>

<style scoped>

</style>
